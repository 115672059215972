<template>
    <div class="m-auto" style="max-width: 960px;">
        <el-card shadow="never" class="m-3">
            <el-timeline class="mt-4">
                <el-timeline-item v-for="(auditFlow, index) in auditFlows" :key="index"
                    :timestamp="$moment.ldt(auditFlow.flowCreateTime)" :color="index==0?'#409eff':''">
                    <div class="text-lg">
                        <span :class="{'text-primary':index==0}">{{auditFlow.name}}</span>
                        <i v-if="auditFlow.flowResult" class="el-icon-success text-success text-xl ml-2"></i>
                        <i v-else class="el-icon-error text-danger text-xl ml-2"></i>
                    </div>
                    <div class="text-info mt-2">操作人：{{auditFlow.userName}}</div>
                    <div class="text-info">备注：{{auditFlow.flowRemark}}</div>
                </el-timeline-item>
            </el-timeline>
        </el-card>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                eventId: this.$route.query.eventId,
                auditFlows: [],
            };
        },
        methods: {
            async loadAuditFlows() {
                let response = await this.$axios.get('/api/AuditFlow/GetAuditFlows', { params: { eventId: this.eventId } });
                this.auditFlows = response.data;
            },
        },
        created() {
            document.title = this.$store.state.applicationName;

            this.loadAuditFlows();
        },
    };
</script>